import React, { useContext, useState } from "react";
import Layout from "../Components/Layout";
import MyContext from "../context/mycontext";
import { TailSpin } from "react-loader-spinner";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Auth } from "../Firebase/Firebase";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const context = useContext(MyContext);
  const { mode, isLoading, setisLoading } = context;
  const navigate = useNavigate();
  const [User, setUser] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const EmailLogin = async (e) => {
    setisLoading(true);
    e.preventDefault();
    if (!User.email || !User.password) {
      toast.error("All Fields are required.");
      return;
    }
    try {
      const email =
        typeof User.email === "string" ? User.email : User.email.toString();
      const result = await signInWithEmailAndPassword(
        Auth,
        email,
        User.password
      );
      localStorage.setItem("Admin", JSON.stringify(result));
      toast.success("Login Successfully.");
      setisLoading(false);
      navigate("/dashboard");
    } catch (error) {
      toast.error("Login Failed.");
      setisLoading(false);
      console.log(error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Layout>
        <main className="md:w-full max-w-md mx-auto p-6">
          <div
            className={`mt-7  border rounded-xl shadow-sm  border-gray-700 ${
              mode === "light"
                ? "bg-white text-gray-800"
                : "bg-gray-800  text-white"
            }`}
          >
            <div className="p-4 sm:p-7">
              <div className="text-center">
                <h1 className="block text-2xl font-bold ">Sign in</h1>
              </div>
              <div className="mt-5">
                {/* Form */}
                <form onSubmit={EmailLogin}>
                  <div className="grid gap-y-4">
                    {/* Form Group */}
                    <div>
                      <label htmlFor="email" className="block text-sm mb-2 ">
                        Email address
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={User.email}
                          onChange={(e) =>
                            setUser({ ...User, email: e.target.value })
                          }
                          className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none text-gray-400 outline-none ${
                            mode === "light"
                              ? "bg-gray-200 border-gray-300"
                              : "bg-slate-900"
                          }`}
                          required
                          aria-describedby="email-error"
                        />
                        <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                          <svg
                            className="size-5 text-red-500"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                          </svg>
                        </div>
                      </div>
                      <p
                        className="hidden text-xs text-red-600 mt-2"
                        id="email-error"
                      >
                        Please include a valid email address so we can get back
                        to you
                      </p>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div>
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="password"
                          className="block text-sm mb-2"
                        >
                          Password
                        </label>
                      </div>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                          id="password"
                          name="password"
                          value={User.password}
                          onChange={(e) =>
                            setUser({ ...User, password: e.target.value })
                          }
                          className={`py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none text-gray-400 outline-none ${
                            mode === "light"
                              ? "bg-gray-200 border-gray-300"
                              : "bg-slate-900"
                          }`}
                          required
                          aria-describedby="password-error"
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible className="h-5 w-5 text-gray-400" />
                          ) : (
                            <AiOutlineEye className="h-5 w-5 text-gray-400" />
                          )}
                        </button>
                      </div>
                      <p
                        className="hidden text-xs text-red-600 mt-2"
                        id="password-error"
                      >
                        8+ characters required
                      </p>
                    </div>

                    <button
                      type="submit"
                      className="mt-4 w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 text-white shadow-sm disabled:opacity-50 disabled:pointer-events-none bg-blue-600"
                    >
                      {isLoading ? (
                        <TailSpin
                          height={20}
                          color={mode === "light" ? "#000" : "#fff"}
                        />
                      ) : (
                        "  Sign in"
                      )}
                    </button>
                  </div>
                </form>
                {/* End Form */}
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Login;
