import React, { useContext } from "react";
import Layout from "../Components/Layout";
import BlogCard from "../Components/BlogCard";
import MyContext from "../context/mycontext";
import { TailSpin } from "react-loader-spinner";
const AllGames = () => {
  const context = useContext(MyContext);
  const { mode, getAllBlog, isLoading } = context;
  return (
    <>
      <Layout>
        <div className="py-10 flex flex-col items-center justify-center w-full">
          <h1 className="text-2xl border-b min-w-[10%] text-center font-bold ">
            All Games
          </h1>
          {isLoading ? (
            <TailSpin height={25} color={mode === "light" ? "#000" : "#fff"} />
          ) : (
            <div className="lg:max-w-[85rem] min-w-[20rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
              {/* Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 ">
                {/* Card */}
                {getAllBlog.length > 0 ? (
                  getAllBlog.map((item, i) => {
                    const { thumbnail, date, id } = item;
                    const Content =
                      item?.blogs?.content &&
                      item?.blogs?.content.split(" ").slice(0, 15).join(" ");
                    return (
                      <div
                        key={id}
                        className="flex justify-center items-center w-full"
                      >
                        <BlogCard
                          thumbnail={thumbnail}
                          date={date}
                          id={id}
                          Content={Content}
                          title={item?.blogs?.title}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="flex justify-center w-full">
                    <h1 className="text-xl">No Blog Found</h1>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default AllGames;
