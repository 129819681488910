import React, { useContext } from "react";
import MyContext from "../context/mycontext";
import { NavLink } from "react-router-dom";

const BlogCard = ({ thumbnail, title, id }) => {
  const context = useContext(MyContext);
  const { mode } = context;
  return (
    <>
      {/* Card Blog */}
      <NavLink
        to={`/gameinfo/${id}`}
        className={`group flex flex-col justify-center items-center shadow-lg rounded-xl border shadow-slate-700/[.7] ${
          mode === "light" ? "bg-[#1C2739]" : "bg-gray-200"
        } ${mode === "light" ? "border-[#1c2739]" : ""}`}
        style={{ width: "200px", height: "200px" }}
      >
        <div className="w-full h-full flex justify-center items-center rounded-xl overflow-hidden">
          <img src={thumbnail} alt="" className="w-full h-full object-cover" />
        </div>
        <h3
          className={`text-center text-xl font-semibold ${
            mode === "light" ? "text-white" : "text-black"
          }  py-2`}
        >
          {title.slice(0, 13)}
        </h3>
      </NavLink>
    </>
  );
};

export default BlogCard;
