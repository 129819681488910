import React, { useState, useContext, useRef } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import myContext from "../context/mycontext";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-tailwind/react";
import { TailSpin } from "react-loader-spinner";
import JoditEditor from "jodit-react";

function CreateBlog({ blogs, setBlogs, setthumbnail, thumbnail, addPost }) {
  const context = useContext(myContext);
  const { mode, isLoading } = context;
  const editor = useRef(null);
  const [text, settext] = useState("");
  //* Create markup function
  function createMarkup(c) {
    return { __html: c };
  }

  return (
    <div className=" container mx-auto max-w-3xl py-6">
      <div
        className="p-5"
        style={{
          background: mode === "dark" ? "#353b48" : "rgb(226, 232, 240)",
          borderBottom:
            mode === "dark"
              ? " 4px solid rgb(226, 232, 240)"
              : " 4px solid rgb(30, 41, 59)",
        }}
      >
        {/* Top Item  */}
        <div className="mb-2 flex justify-between">
          <div className="flex gap-2 items-center">
            {/* Dashboard Link  */}
            <Link to={"/dashboard"}>
              <BsFillArrowLeftCircleFill size={25} />
            </Link>

            {/* Text  */}
            <Typography
              variant="h4"
              style={{
                color: mode === "dark" ? "white" : "black",
              }}
            >
              Create blog
            </Typography>
          </div>
        </div>

        {/* main Content  */}
        <div className="mb-3">
          {/* Thumbnail  */}
          {thumbnail && (
            <img
              className="w-full rounded-md mb-3"
              src={thumbnail ? URL.createObjectURL(thumbnail) : ""}
              alt="thumbnail"
            />
          )}

          {/* Text  */}
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-semibold"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
            Upload Thumbnail
          </Typography>

          {/* First Thumbnail Input  */}
          <input
            type="file"
            label="Upload thumbnail"
            className="text-black shadow-[inset_0_0_4px_rgba(0,0,0,0.6)] placeholder-black w-full rounded-md p-1"
            style={{
              background: mode === "dark" ? "#dcdde1" : "rgb(226, 232, 240)",
            }}
            onChange={(e) => setthumbnail(e.target.files[0])}
          />
        </div>

        {/* Second Title Input */}
        <div className="mb-3">
          <input
            label="Enter your Title"
            className={`shadow-[inset_0_0_4px_rgba(0,0,0,0.6)] w-full rounded-md p-1.5 
                 outline-none text-black ${
                   mode === "dark" ? "placeholder-black" : "placeholder-black"
                 }`}
            placeholder="Enter Your Title"
            style={{
              background: mode === "dark" ? "#dcdde1" : "rgb(226, 232, 240)",
            }}
            name="title"
            onChange={(e) => setBlogs({ ...blogs, title: e.target.value })}
            value={blogs?.title}
          />
        </div>

        {/* Third Category Input  */}
        <div className="mb-3">
          <input
            label="Enter your Category"
            className={`shadow-[inset_0_0_4px_rgba(0,0,0,0.6)] w-full rounded-md p-1.5 
                 outline-none text-black ${
                   mode === "dark" ? "placeholder-black " : "placeholder-black"
                 }`}
            placeholder="Enter Your Category"
            style={{
              background: mode === "dark" ? "#dcdde1" : "rgb(226, 232, 240)",
            }}
            name="category"
            onChange={(e) => setBlogs({ ...blogs, category: e.target.value })}
            value={blogs?.category}
          />
        </div>

        {/* Four Editor  */}
        <JoditEditor
          name="blogs"
          className="text-black"
          ref={editor}
          value={text}
          onChange={(newContent) => {
            settext(newContent);
            setBlogs({ ...blogs, content: newContent });
          }}
        />
        {/* Five Submit Button  */}
        <Button
          onClick={() => addPost()}
          className=" w-full mt-5 flex justify-center"
          style={{
            background:
              mode === "dark" ? "rgb(226, 232, 240)" : "rgb(30, 41, 59)",
            color: mode === "dark" ? "rgb(30, 41, 59)" : "rgb(226, 232, 240)",
          }}
        >
          {isLoading ? (
            <TailSpin height={20} color={mode === "light" ? "#fff" : "#000"} />
          ) : (
            "Send"
          )}
        </Button>

        {/* Six Preview Section  */}
        <div className="">
          <h1 className=" text-center mb-3 text-2xl">Preview</h1>
          <div className="content">
            <div dangerouslySetInnerHTML={createMarkup(blogs?.content)}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBlog;
