import React, { useContext } from "react";
import Layout from "../Components/Layout";
import myContext from "../context/mycontext";
import { Button } from "@material-tailwind/react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { Auth } from "../Firebase/Firebase";
import { TailSpin } from "react-loader-spinner";
import { MdEdit, MdDelete } from "react-icons/md";
function Dashboard() {
  const context = useContext(myContext);
  const { mode, isLoading, setisLoading, getAllBlog, DeleteBlog } = context;
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const navigate = useNavigate();

  const Logout = async () => {
    setisLoading(true);
    try {
      localStorage.removeItem("Admin");
      await signOut(Auth);
      navigate("/adminlogin");
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };
  return (
    <Layout>
      <div className="py-10">
        <div className="flex flex-wrap justify-start items-center lg:justify-center gap-2 lg:gap-10 px-4 lg:px-0 mb-8">
          <div className="left">
            <img
              className=" w-40 h-40  object-cover rounded-full border-2 border-pink-600 p-1"
              src={"https://cdn-icons-png.flaticon.com/128/3135/3135715.png"}
              alt="profile"
            />
          </div>
          <div className="">
            <h1
              className="text-center font-bold text-2xl mb-2  float-start"
              style={{ color: mode === "dark" ? "white" : "black" }}
            >
              Nayak boy
            </h1>

            <h2
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="font-semibold"
            >
              {admin?.user?.email}
            </h2>
            <h2
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="font-semibold"
            >
              <span>Total Blog : </span> {getAllBlog.length}
            </h2>
            <div className=" flex gap-2 mt-2">
              <Link to={"/createblog"}>
                <div className=" mb-2">
                  <Button
                    style={{
                      background:
                        mode === "dark"
                          ? "rgb(226, 232, 240)"
                          : "rgb(30, 41, 59)",
                      color: mode === "dark" ? "black" : "white",
                    }}
                    className="px-8 py-2"
                  >
                    Create Blog
                  </Button>
                </div>
              </Link>
              <div className="mb-2">
                <Button
                  onClick={Logout}
                  style={{
                    background:
                      mode === "dark"
                        ? "rgb(226, 232, 240)"
                        : "rgb(30, 41, 59)",
                    color: mode === "dark" ? "black" : "white",
                  }}
                  className="px-8 py-2"
                >
                  {isLoading ? (
                    <TailSpin
                      height={20}
                      color={mode === "light" ? "#000" : "#fff"}
                    />
                  ) : (
                    "Logout"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Line  */}
        <hr
          className={`border-2
                 ${mode === "dark" ? "border-gray-300" : "border-gray-400"}`}
        />

        {/* Table  */}
        <div className="">
          <div className=" container mx-auto px-4 max-w-7xl my-5">
            <div className="relative overflow-x-auto shadow-md sm:rounded-xl">
              {/* table  */}
              <table className="w-full border-2 border-white shadow-md text-sm text-left text-gray-500 dark:text-gray-400">
                {/* thead  */}
                <thead
                  style={{
                    background: mode === "dark" ? "white" : "rgb(30, 41, 59)",
                  }}
                  className="text-xs "
                >
                  <tr>
                    <th
                      style={{
                        color: mode === "dark" ? "rgb(30, 41, 59)" : "white",
                      }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      S.No
                    </th>
                    <th
                      style={{
                        color: mode === "dark" ? "rgb(30, 41, 59)" : "white",
                      }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      Thumbnail
                    </th>
                    <th
                      style={{
                        color: mode === "dark" ? "rgb(30, 41, 59)" : "white",
                      }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      Title
                    </th>
                    <th
                      style={{
                        color: mode === "dark" ? "rgb(30, 41, 59)" : "white",
                      }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      Category
                    </th>
                    <th
                      style={{
                        color: mode === "dark" ? "rgb(30, 41, 59)" : "white",
                      }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      Date
                    </th>
                    <th
                      style={{
                        color: mode === "dark" ? "rgb(30, 41, 59)" : "white",
                      }}
                      scope="col"
                      className="px-6 py-3"
                    ></th>
                  </tr>
                </thead>

                {/* tbody  */}
                <tbody>
                  {getAllBlog.length > 0 ? (
                    <>
                      {getAllBlog.map((item, i) => {
                        const { thumbnail, date, id } = item;
                        return (
                          <tr
                            key={i}
                            className=" border-b-2"
                            style={{
                              background:
                                mode === "dark" ? "rgb(30, 41, 59)" : "white",
                            }}
                          >
                            {/* S.No   */}
                            <td
                              style={{
                                color: mode === "dark" ? "white" : "black",
                              }}
                              className="px-6 py-4"
                            >
                              {i + 1}.
                            </td>

                            {/* Blog Thumbnail  */}
                            <th
                              style={{
                                color: mode === "dark" ? "white" : "black",
                              }}
                              scope="row"
                              className="px-6 py-4 font-medium "
                            >
                              {/* thumbnail  */}
                              <img
                                className="w-16 rounded-lg curp"
                                src={thumbnail}
                                alt="thumbnail"
                                onClick={() => navigate(`/gameinfo/${id}`)}
                              />
                            </th>

                            {/* Blog Title  */}
                            <td
                              style={{
                                color: mode === "dark" ? "white" : "black",
                              }}
                              className="px-6 py-4"
                            >
                              {item?.blogs?.title.slice(0, 16)}
                            </td>

                            {/* Blog Category  */}
                            <td
                              style={{
                                color: mode === "dark" ? "white" : "black",
                              }}
                              className="px-6 py-4"
                            >
                              {item?.blogs?.category.slice(0, 10)}
                            </td>

                            {/* Blog Date  */}
                            <td
                              style={{
                                color: mode === "dark" ? "white" : "black",
                              }}
                              className="px-6 py-4"
                            >
                              {date}
                            </td>

                            {/* Delete Blog  */}
                            <td
                              style={{
                                color: mode === "dark" ? "white" : "black",
                              }}
                              className="px-6 py-4 flex gap-5"
                            >
                              <NavLink
                                to={`/edit/${id}`}
                                className=" px-4 py-1 rounded-lg text-white font-bold bg-green-500"
                              >
                                <MdEdit size={20} />
                              </NavLink>
                              <button
                                onClick={() => DeleteBlog(id)}
                                className=" px-4 py-1 rounded-lg text-white font-bold bg-red-500"
                              >
                                <MdDelete size={20} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center">
                        <h1>Not found</h1>
                      </div>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
