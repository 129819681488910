import React, { useContext, useState } from "react";
import Layout from "../Components/Layout";
import TextEditor from "../Components/TextEditor";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import toast from "react-hot-toast";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { Storage, FireDB } from "../Firebase/Firebase";
import { useNavigate } from "react-router-dom";
import MyContext from "../context/mycontext";
function CreateBlog() {
  const context = useContext(MyContext);
  const { setisLoading } = context;
  const [blogs, setBlogs] = useState({
    title: "",
    category: "",
    content: "",
    time: Timestamp.now(),
  });
  const [thumbnail, setthumbnail] = useState();
  const navigate = useNavigate();
  //* Add Post Function
  const addPost = async () => {
    if (
      blogs.title === "" ||
      blogs.category === "" ||
      blogs.content === "" ||
      blogs.thumbnail === ""
    ) {
      toast.error("Please Fill All Fields");
    }
    UploadImage();
  };

  //* Upload Image Function
  const UploadImage = async () => {
    setisLoading(true);
    if (!thumbnail) return;
    const Ref = ref(Storage, `blogimage/${blogs.title}`);
    await uploadBytes(Ref, thumbnail);
    const URL = await getDownloadURL(Ref);
    const BlogRef = collection(FireDB, "BlogPost");
    try {
      addDoc(BlogRef, {
        blogs,
        thumbnail: URL,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      });
      navigate("/dashboard");
      setisLoading(false);
      toast.success("Blog Created Successfully.");
      setthumbnail("");
    } catch (error) {
      console.log(error);
      toast.error(error);
      setisLoading(false);
    }
  };
  return (
    <Layout>
      <TextEditor
        blogs={blogs}
        setBlogs={setBlogs}
        thumbnail={thumbnail}
        setthumbnail={setthumbnail}
        addPost={addPost}
      />
    </Layout>
  );
}

export default CreateBlog;
