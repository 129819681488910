import React, { useContext, useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { FireDB, Storage } from "../Firebase/Firebase";
import MyContext from "../context/mycontext";
import {
  getDownloadURL,
  ref,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import toast from "react-hot-toast";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Typography, Button } from "@material-tailwind/react";
import { TailSpin } from "react-loader-spinner";

const UpdateBlog = () => {
  const context = useContext(MyContext);
  const { getAllBlog, setisLoading, mode, isLoading } = context;
  const { id } = useParams();
  const [thumbnail, setThumbnail] = useState();
  const [text, settext] = useState("");
  const navigate = useNavigate();
  const editor = useRef(null);
  const [blogs, setBlogs] = useState({
    title: "",
    category: "",
    content: "",

    time: Timestamp.now(),
    date: new Date().toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
  });

  const getSingleBlog = async () => {
    const blog = getAllBlog.find((blog) => blog.id === id);
    const imgName = blog?.blogs?.title;
    const imgRef = storageRef(Storage, `blogimage/${imgName}`);
    const imgURL = await getDownloadURL(imgRef); // Get the thumbnail URL directly
    setThumbnail(imgURL); // Set thumbnail URL
    try {
      const BlogTemp = await getDoc(doc(FireDB, "BlogPost", id));
      const Blog = BlogTemp.data();
      setBlogs({
        title: Blog?.blogs?.title,
        category: Blog?.blogs?.category,
        content: Blog?.blogs?.content,
        time: Blog?.time,
        date: Blog?.date,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateBlog = async () => {
    setisLoading(true);
    const Ref = ref(Storage, `blogimage/${blogs.title}`);
    await uploadBytes(Ref, thumbnail);
    const URL = await getDownloadURL(Ref);
    const BlogRef = doc(FireDB, "BlogPost", id);
    try {
      updateDoc(BlogRef, {
        blogs,
        thumbnail: URL,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      });
      navigate("/dashboard");
      setisLoading(false);
      toast.success("Blog Update Successfully.");
    } catch (error) {
      console.log(error);
      toast.error(error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    getSingleBlog();
  }, []);
  function createMarkup(c) {
    return { __html: c };
  }

  const texteditor = text === "" ? blogs.content : text;
  return (
    <div className=" container mx-auto max-w-3xl py-6">
      <div
        className="p-5"
        style={{
          background: mode === "dark" ? "#353b48" : "rgb(226, 232, 240)",
          borderBottom:
            mode === "dark"
              ? " 4px solid rgb(226, 232, 240)"
              : " 4px solid rgb(30, 41, 59)",
        }}
      >
        {/* Top Item  */}
        <div className="mb-2 flex justify-between">
          <div className="flex gap-2 items-center">
            {/* Dashboard Link  */}
            <Link to={"/dashboard"}>
              <BsFillArrowLeftCircleFill size={25} />
            </Link>

            {/* Text  */}
            <Typography
              variant="h4"
              style={{
                color: mode === "dark" ? "white" : "black",
              }}
            >
              Create blog
            </Typography>
          </div>
        </div>

        {/* main Content  */}
        <div className="mb-3">
          {/* Thumbnail  */}
          {thumbnail && (
            <img
              className="w-full rounded-md mb-3"
              src={thumbnail}
              alt="thumbnail"
            />
          )}

          {/* Text  */}
          <Typography
            variant="small"
            color="blue-gray"
            className="mb-2 font-semibold"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
            Upload Thumbnail
          </Typography>

          {/* First Thumbnail Input  */}
          <input
            type="file"
            label="Upload thumbnail"
            className="text-black shadow-[inset_0_0_4px_rgba(0,0,0,0.6)] placeholder-black w-full rounded-md p-1"
            style={{
              background: mode === "dark" ? "#dcdde1" : "rgb(226, 232, 240)",
            }}
            onChange={(e) => setThumbnail(e.target.files[0])}
          />
        </div>

        {/* Second Title Input */}
        <div className="mb-3">
          <input
            label="Enter your Title"
            className={`shadow-[inset_0_0_4px_rgba(0,0,0,0.6)] w-full rounded-md p-1.5 
                 outline-none text-black ${
                   mode === "dark" ? "placeholder-black" : "placeholder-black"
                 }`}
            placeholder="Enter Your Title"
            style={{
              background: mode === "dark" ? "#dcdde1" : "rgb(226, 232, 240)",
            }}
            name="title"
            onChange={(e) => setBlogs({ ...blogs, title: e.target.value })}
            value={blogs?.title}
          />
        </div>

        {/* Third Category Input  */}
        <div className="mb-3">
          <input
            label="Enter your Category"
            className={`shadow-[inset_0_0_4px_rgba(0,0,0,0.6)] w-full rounded-md p-1.5 
                 outline-none text-black ${
                   mode === "dark" ? "placeholder-black " : "placeholder-black"
                 }`}
            placeholder="Enter Your Category"
            style={{
              background: mode === "dark" ? "#dcdde1" : "rgb(226, 232, 240)",
            }}
            name="category"
            onChange={(e) => setBlogs({ ...blogs, category: e.target.value })}
            value={blogs?.category}
          />
        </div>

        {/* Four Editor  */}
        <JoditEditor
          name="blogs"
          className="text-black"
          ref={editor}
          value={texteditor}
          onChange={(newContent) => {
            settext(newContent);
            setBlogs({ ...blogs, content: newContent });
          }}
        />
        {/* Five Submit Button  */}
        <Button
          onClick={() => updateBlog()}
          className=" w-full mt-5 flex justify-center"
          style={{
            background:
              mode === "dark" ? "rgb(226, 232, 240)" : "rgb(30, 41, 59)",
            color: mode === "dark" ? "rgb(30, 41, 59)" : "rgb(226, 232, 240)",
          }}
        >
          {isLoading ? <TailSpin height={20} color={"#000"} /> : "Update"}
        </Button>

        {/* Six Preview Section  */}
        <div className="">
          <h1 className=" text-center mb-3 text-2xl">Preview</h1>
          <div className="content">
            <div
              className={`[&> h1]:text-[32px] [&>h1]:font-bold  [&>h1]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h1]:text-[#ff4d4d]"
                            : "[&>h1]:text-black"
                        }

                        [&>h2]:text-[24px] [&>h2]:font-bold [&>h2]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h2]:text-white"
                            : "[&>h2]:text-black"
                        }

                        [&>h3]:text-[18.72] [&>h3]:font-bold [&>h3]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h3]:text-white"
                            : "[&>h3]:text-black"
                        }

                        [&>h4]:text-[16px] [&>h4]:font-bold [&>h4]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h4]:text-white"
                            : "[&>h4]:text-black"
                        }

                        [&>h5]:text-[13.28px] [&>h5]:font-bold [&>h5]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h5]:text-white"
                            : "[&>h5]:text-black"
                        }

                        [&>h6]:text-[10px] [&>h6]:font-bold [&>h6]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h6]:text-white"
                            : "[&>h6]:text-black"
                        }

                        [&>p]:text-[16px] [&>p]:mb-1.5
                        ${
                          mode === "dark"
                            ? "[&>p]:text-[#7efff5]"
                            : "[&>p]:text-black"
                        }

                        [&>ul]:list-disc [&>ul]:mb-2
                        ${
                          mode === "dark"
                            ? "[&>ul]:text-white"
                            : "[&>ul]:text-black"
                        }

                        [&>ol]:list-decimal [&>li]:mb-10
                        ${
                          mode === "dark"
                            ? "[&>ol]:text-white"
                            : "[&>ol]:text-black"
                        }

                        [&>li]:list-decimal [&>ol]:mb-2
                        ${
                          mode === "dark"
                            ? "[&>ol]:text-white"
                            : "[&>ol]:text-black"
                        }

                        [&>img]:rounded-lg
                        `}
              dangerouslySetInnerHTML={createMarkup(blogs?.content)}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBlog;
