import React, { useContext } from "react";
import MyContext from "../context/mycontext";
import BlogCard from "../Components/BlogCard";
import { NavLink } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const Blogs = () => {
  const context = useContext(MyContext);
  const { mode, getAllBlog, isLoading } = context;

  return (
    <>
      <div
        id="down"
        className={`flex flex-col min-h-[10vh] border-t-2 ${
          mode === "light" && "border-gray-400"
        } max-w-[90%] mx-auto`}
      >
        {isLoading ? (
          <span className="mt-10">
            <TailSpin height={25} color={mode === "light" ? "#000" : "#fff"} />
          </span>
        ) : (
          <div className="lg:max-w-[85rem] min-w-[20rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            {/* Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 ">
              {/* Card */}
              {getAllBlog.length > 0 ? (
                getAllBlog.slice(0, 6).map((item, i) => {
                  const { thumbnail, date, id } = item;
                  const Content =
                    item?.blogs?.content &&
                    item?.blogs?.content.split(" ").slice(0, 15).join(" ");
                  return (
                    <div
                      key={id}
                      className="flex justify-center items-center w-full"
                    >
                      <BlogCard
                        thumbnail={thumbnail}
                        date={date}
                        id={id}
                        Content={Content}
                        title={item?.blogs?.title}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center w-full">
                  <h1 className="text-xl">No Blog Found</h1>
                </div>
              )}
            </div>
          </div>
        )}

        {getAllBlog.length > 0 && (
          <div className="flex justify-center md:my-5 mb-3">
            <NavLink
              to={"/allgames"}
              style={{
                background:
                  mode === "dark" ? "rgb(226, 232, 240)" : "rgb(30, 41, 59)",
                color:
                  mode === "dark" ? "rgb(30, 41, 59)" : "rgb(226, 232, 240)",
              }}
              className="px-3 py-2 rounded-lg font-semibold"
            >
              All Games
            </NavLink>
          </div>
        )}
      </div>
    </>
  );
};

export default Blogs;
