import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBZJnJHIT7ij9ZQpkrKEbnRrHWwxuDkLGY",
  authDomain: "tech-360-6d8b6.firebaseapp.com",
  projectId: "tech-360-6d8b6",
  storageBucket: "tech-360-6d8b6.appspot.com",
  messagingSenderId: "350610427432",
  appId: "1:350610427432:web:423625229473063d046f1d",
};

const app = initializeApp(firebaseConfig);

const Auth = getAuth(app);
const Storage = getStorage(app);
const FireDB = getFirestore(app);

export { Auth, Storage, FireDB, app };
