import React, { useContext } from "react";
import MyContext from "../context/mycontext";

const HeroSection = () => {
  const context = useContext(MyContext);
  const { mode } = context;
  return (
    <>
      {/* Hero */}
      <div className="">
        <div className="bg-gradient-to-b from-violet-600/[.15] via-transparent ">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-6">
            {/* Announcement Banner */}

            {/* End Announcement Banner */}
            {/* Title */}
            <div className="max-w-3xl text-center mx-auto">
              <h1
                className={`block font-medium text-4xl sm:text-5xl md:text-6xl lg:text-5xl ${
                  mode === "dark" && "text-gray-300"
                }`}
              >
                Let's Explore New Games
              </h1>
            </div>

            {/* Buttons */}
            <div className="text-center">
              <a
                className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-gray-800"
                href="#down"
              >
                Let's Explore
              </a>
            </div>
            {/* End Buttons */}
          </div>
        </div>
      </div>
      {/* End Hero */}
    </>
  );
};

export default HeroSection;
