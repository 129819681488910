import React from "react";
import { NavLink } from "react-router-dom";
const NoPage = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-[50vh] my-5">
        <img src="./images/not-found.png" alt="" />
        <NavLink
          to="/"
          className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500  font-bold rounded-full transition-transform transform-gpu  hover:shadow-lg"
        >
          Go Back
        </NavLink>
      </div>
    </>
  );
};

export default NoPage;
