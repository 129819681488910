import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyContext from "../context/mycontext";

const SearchBar = () => {
  const [search, setSearch] = useState(""); // State to store search query
  const navigate = useNavigate(); // Navigation hook
  const context = useContext(MyContext);
  const { getAllBlog } = context;

  return (
    <div className="">
      {/* search input  */}
      <div className="input flex justify-center">
        <input
          type="text"
          placeholder="Search here..."
          onChange={(e) => setSearch(e.target.value)}
          className="bg-gray-400 placeholder-gray-100 rounded-lg px-2 py-2 w-96 lg:w-96 md:w-96 outline-none text-white "
        />
      </div>
      {/* search drop-down  */}
      <div className="flex justify-center ">
        {search && (
          <div className="block absolute bg-gray-200 w-96 md:w-96 lg:w-96 z-50 my-1 rounded-lg px-2 py-2">
            {getAllBlog.length > 0 ? (
              getAllBlog
                .filter((obj) =>
                  obj.blogs.title
                    .toLowerCase()
                    .includes(search.toLocaleLowerCase())
                )
                .map((blog, index) => (
                  <div key={index} className="p-2 flex justify-center">
                    <div
                      onClick={() => navigate(`/gameinfo/${blog.id}`)}
                      className="container cursor-pointer bg-gray-300 text-black p-2 rounded-lg border border-black flex items-center gap-3 text-left"
                    >
                      <img
                        className="w-[100px] h-[50px] mb-2 rounded-md"
                        src={blog?.thumbnail} // Use blog image
                        alt={blog?.blogs?.title} // Use blog name as alt text
                      />
                      <div>
                        <span className="w-40 text-[12px]">{blog.date}</span>
                        <h1 className="text-xl font-bold">
                          {blog?.blogs?.title.slice(0, 16)}
                        </h1>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="flex justify-center">
                <img
                  className="w-20"
                  src="https://cdn-icons-png.flaticon.com/128/10437/10437090.png"
                  alt="img"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
