import React, { useContext, useState } from "react";
import MyContext from "../context/mycontext";
import { FaMoon, FaBars, FaXmark } from "react-icons/fa6";
import { FiSun } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import SearchBar from "./SearchModal";

const Navbar = () => {
  const context = useContext(MyContext);
  const { mode, toggleMode } = context;
  const [isMobileMenu, setisMobileMenu] = useState(false);

  const handleMobileMenuToggle = () => {
    setisMobileMenu(!isMobileMenu);
  };

  const User = JSON.parse(localStorage.getItem("Admin"));

  return (
    <>
      <nav
        className={`flex justify-between items-center h-16 px-6 w-full shadow-lg bg-[#1C2739]rounded-lg ${
          mode === "light" ? "text-black" : "text-white"
        }`}
      >
        <NavLink to={"/"}>
          {mode === "light" ? (
            <img
              className="h-8 w-[100%]"
              src="/images/logo-black.png"
              alt="logo"
            />
          ) : (
            <img
              className="h-8 w-[100%]"
              src="/images/logo-white.png"
              alt="logo"
            />
          )}
        </NavLink>
        <ul className="flex items-center justify-between gap-6">
          <div className="md:flex items-center justify-between gap-6 hidden">
            <NavLink aria-label="Home" to={"/"}>
              Home
            </NavLink>
            <NavLink aria-label="All Games" to={"/allgames"}>
              All Games
            </NavLink>
            {!User && (
              <NavLink aria-label="Login" to={"/adminlogin"}>
                Login
              </NavLink>
            )}
          </div>
          <li className="md:block hidden">
            <SearchBar />
          </li>

          {User && (
            <NavLink to={"/dashboard"} aria-label="profile">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                className="h-8 w-8"
                alt=""
              />
            </NavLink>
          )}
          <li onClick={() => toggleMode(!mode)}>
            {mode === "light" ? <FaMoon size={22} /> : <FiSun size={25} />}
          </li>
          <li onClick={handleMobileMenuToggle} className="md:hidden">
            {isMobileMenu ? <FaXmark size={20} /> : <FaBars size={20} />}
          </li>
        </ul>
      </nav>
      <li className="md:hidden block md:px-0 px-2">
        <SearchBar />
      </li>
      <div
        className={`md:hidden  flex flex-col text-center gap-3  top-16 left-0 w-full bg-[#1C2739] rounded-lg text-white py-4 px-6 ${
          isMobileMenu
            ? "block transition-all duration-500 ease-in-out delay-500"
            : "hidden"
        }`}
      >
        <NavLink aria-label="Home" to={"/"} onClick={handleMobileMenuToggle}>
          Home
        </NavLink>
        <NavLink
          aria-label="All Games"
          to={"/allgames"}
          onClick={handleMobileMenuToggle}
        >
          All Games
        </NavLink>
        {!User && (
          <NavLink
            aria-label="Login"
            to={"/adminlogin"}
            onClick={handleMobileMenuToggle}
          >
            Login
          </NavLink>
        )}
      </div>
    </>
  );
};

export default Navbar;
