import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import MyState from "./context/mystate";
import Login from "./Pages/Login";
import AllGames from "./Pages/Allgames";
import Dashboard from "./Pages/Dashboard";
import CreateBlog from "./Pages/CreateBlog";
import NoPage from "./Pages/NoPage";
import Blogs from "./Pages/Blogs";
import GameInfo from "./Pages/GameInfo";
import { Toaster } from "react-hot-toast";
import ScrollTop from "./Components/ScrollTop";
import UpdateBlog from "./Pages/UpdateBlog";

const App = () => {
  return (
    <>
      <MyState>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/allgames" element={<AllGames />} />
          <Route path="/gameinfo/:id" element={<GameInfo />} />
          <Route path="/adminlogin" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <AdminRoutesProtect>
                <Dashboard />
              </AdminRoutesProtect>
            }
          />
          <Route
            path="/createblog"
            element={
              <AdminRoutesProtect>
                <CreateBlog />
              </AdminRoutesProtect>
            }
          />
          <Route
            path="/edit/:id"
            element={
              <AdminRoutesProtect>
                <UpdateBlog />
              </AdminRoutesProtect>
            }
          />
          <Route path="/*" element={<NoPage />} />
        </Routes>
        <Toaster />
      </MyState>
    </>
  );
};

export default App;

export const AdminRoutesProtect = ({ children }) => {
  const admin = JSON.parse(localStorage.getItem("Admin"));

  if (admin?.user?.email === "boynayak31@gmail.com") {
    return children;
  } else {
    return <Navigate to={"/adminlogin"} />;
  }
};
