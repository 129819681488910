import {
  Timestamp,
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FireDB } from "../Firebase/Firebase";

const Comment = ({ id }) => {
  const [comment, setcomment] = useState({
    name: "",
    text: "",
  });
  const [comments, setcomments] = useState([]);
  const CRef = collection(FireDB, `BlogPost/${id}/comments/`);

  const addComment = async (e) => {
    e.preventDefault();

    try {
      await addDoc(CRef, {
        comment,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      });

      toast.success("Comment Add Successfully");
      setcomment({
        name: "",
        text: "",
      });
    } catch (error) {
      console.log(error);

      toast.error("Somthing Went Wrong.");
    }
  };

  const getComments = async () => {
    try {
      const q = query(CRef, orderBy("time", "desc"));
      onSnapshot(q, (QuerySnapshot) => {
        let commentArray = [];
        QuerySnapshot.forEach((doc) => {
          commentArray.push({ ...doc.data(), id: doc.id });
        });
        setcomments(commentArray);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getComments();
  }, []);
  return (
    <>
      <section className="not-format">
        <div className="flex justify-between items-center mb-6">
          <span className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
            Discussion ({comments.length})
          </span>
        </div>
        <form onSubmit={addComment} className="mb-6">
          <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <label htmlFor="comment" className="sr-only">
              Your comment
            </label>

            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 dark:text-white dark:placeholder-gray-400 dark:bg-gray-800 border-none outline-none bg-transparent"
              required
              value={comment.name}
              onChange={(e) => setcomment({ ...comment, name: e.target.value })}
            />
          </div>
          <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <label htmlFor="comment" className="sr-only">
              Your comment
            </label>

            <textarea
              id="comment"
              rows={6}
              name="text"
              className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 dark:text-white dark:placeholder-gray-400 dark:bg-gray-800 border-none outline-none"
              placeholder="Write a comment..."
              required
              value={comment.text}
              onChange={(e) => setcomment({ ...comment, text: e.target.value })}
            />
          </div>
          <button
            type="submit"
            className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800 "
          >
            Post comment
          </button>
        </form>
        {comments.map((item, i) => {
          const { date } = item;
          return (
            <article
              key={i}
              className=" p-3 mb-6 md:mx-0 mx-5 text-base bg-gray-300 text-black rounded-md border-t border-gray-200"
            >
              <footer className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <p className="inline-flex items-center mr-3 font-semibold text-[18px]">
                    {item?.comment?.name}
                  </p>
                  <p className="text-sm text-gray-600 ">
                    <time
                      pubdate="true"
                      dateTime="2022-02-08"
                      title="February 8th, 2022"
                    >
                      {date}
                    </time>
                  </p>
                </div>
              </footer>
              <p className="">{item?.comment?.text}</p>
            </article>
          );
        })}
      </section>
    </>
  );
};

export default Comment;
