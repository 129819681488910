import React, { useState, useEffect } from "react";
import MyContext from "./mycontext";
import {
  collection,
  deleteDoc,
  onSnapshot,
  orderBy,
  query,
  doc,
} from "firebase/firestore";
import { FireDB, Storage } from "../Firebase/Firebase";
import toast from "react-hot-toast";
import { deleteObject, ref } from "firebase/storage";

function MyState(props) {
  const getTheme = () => {
    const isDarkMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    return isDarkMode ? "dark" : "light";
  };

  const [mode, setMode] = useState(getTheme());
  const [isLoading, setisLoading] = useState(false);
  const [getAllBlog, setgetAllBlog] = useState([]);

  useEffect(() => {
    localStorage.setItem("mode", mode);
    if (mode === "light") {
      document.body.style.backgroundColor = "white";
      document.body.style.color = "rgb(17, 24, 39)";
    } else {
      document.body.style.backgroundColor = "rgb(17, 24, 39)";
      document.body.style.color = "white";
    }
  }, [mode]);

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const getAllBlogs = () => {
    setisLoading(true);
    try {
      const q = query(collection(FireDB, "BlogPost"), orderBy("time", "desc"));
      onSnapshot(q, (QuerySnapshot) => {
        let BlogArray = [];
        QuerySnapshot.forEach((doc) => {
          BlogArray.push({ ...doc.data(), id: doc.id });
        });
        setgetAllBlog(BlogArray);
        setisLoading(false);
      });
    } catch (error) {
      console.log(error);
      setisLoading(true);
    }
  };

  const DeleteBlog = async (id) => {
    try {
      const blog = getAllBlog.find((blog) => blog.id === id);
      const imgName = blog?.blogs?.title;
      const imgRef = ref(Storage, `blogimage/${imgName}`);
      await deleteObject(imgRef);
      await deleteDoc(doc(FireDB, "BlogPost", id));
      getAllBlogs();
      toast.success("Blogs deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <MyContext.Provider
      value={{
        mode,
        toggleMode,
        isLoading,
        setisLoading,
        getAllBlog,
        DeleteBlog,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
