import React, { useContext, useEffect, useState } from "react";
import Layout from "../Components/Layout";
import MyContext from "../context/mycontext";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { FireDB } from "../Firebase/Firebase";
import toast from "react-hot-toast";
import Comment from "../Components/Comment";
import { TailSpin } from "react-loader-spinner";
const GameInfo = () => {
  const context = useContext(MyContext);
  const { id } = useParams();
  const { mode, isLoading, setisLoading } = context;
  const [bloginfo, setbloginfo] = useState();

  const getbloginfo = async () => {
    setisLoading(true);
    try {
      const blogTemp = await getDoc(doc(FireDB, "BlogPost", id));
      if (blogTemp.exists()) {
        setbloginfo(blogTemp.data());
        setisLoading(false);
      } else {
        toast.error("Blog Not found.");
      }
    } catch (error) {
      console.log(error);
      setisLoading(true);
    }
  };

  useEffect(() => {
    getbloginfo();
    window.scrollTo(0, 0);
  }, []);

  function createMarkup(c) {
    return { __html: c };
  }
  return (
    <>
      <Layout>
        <div>
          <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 antialiased md:px-0 px-3">
            <div
              className={`flex ${
                isLoading ? "justify-center " : "justify-between"
              }px-4 mx-auto max-w-screen-xl `}
            >
              {isLoading ? (
                <TailSpin
                  height={25}
                  color={mode === "light" ? "#000" : "#fff"}
                />
              ) : (
                <>
                  <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert ">
                    <figure>
                      <img
                        src={bloginfo?.thumbnail}
                        className=""
                        alt={bloginfo?.blogs?.title}
                      />
                      <figcaption className="py-2">
                        <p className="text-base text-gray-500 dark:text-gray-400 ">
                          <time
                            pubdate="true"
                            dateTime="2022-02-08"
                            title="February 8th, 2022"
                          >
                            {bloginfo?.date}
                          </time>
                        </p>
                      </figcaption>
                    </figure>
                    <div className="mb-4 lg:mb-6 not-format md:pr-0 pr-5">
                      <h1 className="mb-4 text-3xl font-extrabold leading-tight lg:mb-6 lg:text-4xl ">
                        {bloginfo?.blogs?.title}
                      </h1>
                    </div>
                    <section className="my-2 text-justify ">
                      <div
                        className={`[&> h1]:text-[32px] [&>h1]:font-bold  [&>h1]:mb-2.5
                        ${
                          mode === "dark"
                            ? "[&>h1]:text-[#ff4d4d]"
                            : "[&>h1]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>h2]:text-white"
                            : "[&>h2]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>h3]:text-white"
                            : "[&>h3]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>h4]:text-white"
                            : "[&>h4]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>h5]:text-white"
                            : "[&>h5]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>h6]:text-white"
                            : "[&>h6]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>p]:text-[#7efff5]"
                            : "[&>p]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>ul]:text-white"
                            : "[&>ul]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>ol]:text-white"
                            : "[&>ol]:text-black"
                        }
                        ${
                          mode === "dark"
                            ? "[&>ol]:text-white"
                            : "[&>ol]:text-black"
                        }
                        `}
                        dangerouslySetInnerHTML={createMarkup(
                          bloginfo?.blogs?.content
                        )}
                      ></div>
                    </section>
                    <Comment id={id} />
                  </article>
                </>
              )}
            </div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default GameInfo;
